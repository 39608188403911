<template>
  <a-drawer
    width="700"
    :title="detail.name"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <a-form
      ref="RefForm"
      name="basic"
      autocomplete="off"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 剧名 -->
      <a-form-item label="剧名">
        <div>{{ detail.name || '暂无' }}</div>
      </a-form-item>
      <!-- 封面 -->
      <a-form-item label="封面" :style="`margin-bottom: ${detail.cover && detail.cover.length ? 0 : 24}px;`">
        <div class="cover-list" v-if="detail.cover && detail.cover.length">
          <div class="cover-item" v-for="(item, index) in detail.cover" :key="index">
            <img :src="$pub.CDN_URL(item.url)" />
            <a class="download-item" @click="touchDownload(item)">下载</a>
          </div>
        </div>
        <span v-else>暂无</span>
      </a-form-item>
      <!-- 剧情类型 -->
      <a-form-item label="剧情类型">
        <div>{{ detail.drama_types && detail.drama_types.join('、') || '暂无' }}</div>
      </a-form-item>
      <!-- 剧情简介 -->
      <a-form-item label="剧情简介">
        <div>{{ detail.description ? detail.description : '暂无' }}</div>
      </a-form-item>
      <!-- 爆点梳理 -->
      <a-form-item label="爆点梳理">
        <a v-if="detail.detonation_point_url" :href="detail.detonation_point_url" target='_blank'>点击查看</a>
        <span v-else>暂无</span>
      </a-form-item>
    </a-form>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue"
import Loading from "@/components/Loading"
import { agentDramaDetail } from "@/api/promotion"
import { message } from "ant-design-vue"
import { DOWLOAD_PROXY_ALL } from "@/utils/download"
import Pub from '@/utils/public'

// 加载
let isLoading = ref(false)
// form
const RefForm = ref(null)
// 片库ID
let id = ref(undefined)
// 详情
let detail = ref({})
// 抽屉展示状态
const visible = ref(false)
// 打开抽屉
function showDrawer(data) {
  id.value = data.id
  visible.value = true
  // 获取详情数据
  getDetail()
}
// 关闭抽屉
function onClose() {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 下载图片
function touchDownload (item) {
  DOWLOAD_PROXY_ALL(Pub.CDN_URL(item.url))
}

//  详情
function getDetail() {
  isLoading.value = true
  const params = {
    id: id.value
  };
  agentDramaDetail(params).then((res) => {
    isLoading.value = false;
    const { code, data, msg } = res
    if (code === 0) {
      nextTick(() => {
        // 详情
        detail.value = data
        // 剧情类型
        const drama_types = []
        data.drama_types.forEach((item) => {
          drama_types.push(item.name)
        })
        detail.value.drama_types = drama_types
      })
    } else {
      message.error(res.message || msg)
    }
  });
}

// 暴露出去
defineExpose({
  showDrawer
});
</script>

<style scoped>
.cover-list {
  display: flex;
  flex-wrap: wrap;
}
.cover-item {
  width: 118px;
  padding: 0 10px 0 10px;
}
.cover-item img {
  display: block;
  width: 100%;
  height: 148px;
}
.download-item {
  display: block;
  padding: 10px 0;
}
</style>
